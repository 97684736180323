const NODE = 'https://testnet.veblocks.net/'
const NETWORK = 'test'
const PROJECT_NAME = 'Gangsta Gorillas'
const GG_CONTRACT = '0x5Bb46f2504BDFDF7BEf666B88ea9aFcd39aEC213'
const BC_CONTRACT = '0xD7A8B0CEBED38164C463e39F9F433dAf963c5CFb'
const CJ_MINT_CONTRACT = '0x37f264a6DA73DD49ef1520C07B31bF9358bFA0eA' // todo: fill correct contract
const EMPTY_PLOT_CONTRACT = '0x540501606D17d411F5c0a39242e4dF4223da91a2' // todo: fill correct contract
const BUILDING_CONTRACT = '0x6198dbDaCa4AD5fC3352108f20Fd9F91eC7C356C' // todo: fill correct contract
const ADDRESS_KEY = 'test_address'

// const NODE = 'https://mainnet.veblocks.net/'
// const NETWORK = 'main'
// const PROJECT_NAME = 'Gangsta Gorillas'
// const GG_CONTRACT = '0xA5e2EE50Cb49EA4d0a3A520C15aa4cFfAF5eA026'
// const BC_CONTRACT = '0xD7A8B0CEBED38164C463e39F9F433dAf963c5CFb'
// const CJ_MINT_CONTRACT = '' // todo: fill correct contract
// const EMPTY_PLOT_CONTRACT = '' // todo: fill correct contract
// const BUILDING_CONTRACT = '' // todo: fill correct contract
// const ADDRESS_KEY = 'address';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    USER_ADDRESS,
    ADDRESS_KEY,
    GG_CONTRACT,
    BC_CONTRACT,
    CJ_MINT_CONTRACT,
    EMPTY_PLOT_CONTRACT,
    BUILDING_CONTRACT
}
