import {bananaCrackNftAbi} from "../constants/bananaCrackNftAbi";
import {bananaCrackContract} from "../constants/contract";
import {useEffect, useState} from "react";
import {USER_ADDRESS} from "../constants";
import {getMetadata} from "../components/functions/NftRarityTool";
import {NftContract} from "../components/functions/NftContract";
import transferIcon from '../images/2879357.png'
import {closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BananaCrack = () => {
  const [metadata, setMetadata] = useState([]);
  const [recipient, setRecipient] = useState('');
  const [balanceOf, setBalanceOf] = useState(0)
  const [attributesModelData, setAttributesModelData] = useState([]);
  const [transferModelData, setTransferModelData] = useState(null);
  const [nftContract] = useState(new NftContract());
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    await initialize();
  }, []);

  const initialize = async () => {
    if (USER_ADDRESS) {
      await loadAssets();
    }


  }

  const loadAssets = async () => {
    const walletOfOwner = await nftContract.walletOfOwner(bananaCrackNftAbi, bananaCrackContract);
    setBalanceOf(walletOfOwner.length)
    const metas = await getMetadata(walletOfOwner, 'bananacrack', false, false)
    setMetadata([...metas])

  }

  import('../Your-Gorillaz.css')
  return <div style={{paddingTop: '170px'}}>
    <section className="u-clearfix u-custom-color-1 u-section-1" id="sec-2931">
      <div className="u-clearfix u-sheet u-sheet-1">
        <h1 className="u-custom-font u-text u-text-body-alt-color u-text-default u-title u-text-1">Your Bananacrack</h1>
        <h5 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-2">Total
          Bananacrack: {balanceOf}</h5>
        <div className="u-expanded-width u-list u-list-1">
          <div className="u-repeater u-repeater-1">
            {metadata.map((token, index) =>
              <div key={index}
                   className="u-border-2 u-border-custom-color-4 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                <div className="u-container-layout u-similar-container u-container-layout-1">
                  <h3 className="u-custom-font u-text u-text-default u-text-3">#{token.result.edition}</h3>
                  <div className="u-border-4 u-border-custom-color-1 u-line u-line-horizontal u-line-1"></div>
                  <LazyLoadImage alt="" className="u-image u-image-default u-image-1" data-image-width="1250"
                       data-image-height="1920" src={token.img}/>
                  <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                    <a
                      className="u-active-none u-border-none u-btn u-button-link u-button-style u-custom-font u-dialog-link u-hover-none u-none u-text-black u-btn-1"
                      href="#sec-1e3f"
                      onClick={() => handleAttributesClick(token, setAttributesModelData)}> Attributes</a>
                  </p>
                  <a href="#sec-8f81" onClick={() => handleTransferClick(index, 'sec-8f81', setTransferModelData)}
                     className="u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-dialog-link u-hover-palette-4-dark-3 u-radius-50 u-btn-2">Transfer</a>
                  <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                  <a className="u-border-none u-btn u-button-link u-button-style u-custom-font u-hover-none u-none u-text-black u-btn-1" target="_blank"
                     href={token.imgOriginal}>View original</a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
    <section
      className="u-align-center u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-4"
      id="sec-1e3f">
      <div
        className="u-align-center u-border-2 u-border-custom-color-3 u-container-style u-custom-color-2 u-dialog u-radius-14 u-shape-round u-dialog-1">
        <div className="u-container-layout u-valign-top-xs u-container-layout-1">
          {attributesModelData.result ?
            <img className="u-expand-resize u-expanded-width-xs u-image u-image-1" data-image-width="1250"
                 data-image-height="1920" src={attributesModelData.img}/> : null}
          {attributesModelData.result ?
            <h3 className="u-custom-font u-text u-text-1">#{attributesModelData.result.edition}</h3> : null}
          <p className="u-custom-font u-text u-text-2">
            <span style={{fontSize: '1.25rem', fontWeight: '700'}}>Crack Traits:</span>
            {attributesModelData.result && attributesModelData.result.attributes.map((attribute, index) =>
              <p style={{margin: '0'}} key={index}>
                {attribute.trait_type}: {attribute.value}
              </p>
            )}
          </p>
        </div>
        <button className="u-dialog-close-button u-icon u-text-grey-50 u-icon-1">
          <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 409.806 409.806">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-4e2d"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
               xmlSpace="preserve" className="u-svg-content" viewBox="0 0 409.806 409.806" x="0px" y="0px" id="svg-4e2d"
          >
            <g>
              <g>
                <path
                  d="M228.929,205.01L404.596,29.343c6.78-6.548,6.968-17.352,0.42-24.132c-6.548-6.78-17.352-6.968-24.132-0.42    c-0.142,0.137-0.282,0.277-0.42,0.42L204.796,180.878L29.129,5.21c-6.78-6.548-17.584-6.36-24.132,0.42    c-6.388,6.614-6.388,17.099,0,23.713L180.664,205.01L4.997,380.677c-6.663,6.664-6.663,17.468,0,24.132    c6.664,6.662,17.468,6.662,24.132,0l175.667-175.667l175.667,175.667c6.78,6.548,17.584,6.36,24.132-0.42    c6.387-6.614,6.387-17.099,0-23.712L228.929,205.01z"></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </section>
    <style> {`.u-dialog-section-4 {
    min - height: 826px;
  }

    .u-dialog-section-4 .u-dialog-1 {
      width: 800px;
      min-height: 608px;
      background-image: none;
      margin: 165px auto 60px;
    }

    .u-dialog-section-4 .u-container-layout-1 {
      padding: 45px 30px 30px;
    }

    .u-dialog-section-4 .u-image-1 {
      width: 328px;
      height: 518px;
      margin: 8px 12px 0 auto;
    }

    .u-dialog-section-4 .u-text-1 {
      font - family: "Alfa Slab One";
      margin: -477px 359px 0 19px;
    }

    .u-dialog-section-4 .u-text-2 {
      font - family: "Akaya Kanadaka";
      margin: 32px 359px 0 19px;
    }

    .u-dialog-section-4 .u-icon-1 {
      width: 16px;
      height: 16px;
      left: auto;
      top: 14px;
      position: absolute;
      right: 14px;
    }

    @media (max-width: 1199px) {
      .u-dialog-section-4 .u-dialog-1 {
      height: auto;
    }

      .u-dialog-section-4 .u-text-1 {
      margin-top: -477px;
    }
    }

    @media (max-width: 991px) {
      .u-dialog-section-4 .u-dialog-1 {
      width: 720px;
      min-height: 533px;
      margin-top: 138px;
    }

      .u-dialog-section-4 .u-container-layout-1 {
      padding-top: 29px;
    }

      .u-dialog-section-4 .u-image-1 {
      width: 330px;
    }

      .u-dialog-section-4 .u-text-1 {
      width: auto;
      margin-right: 360px;
      margin-left: 0;
    }

      .u-dialog-section-4 .u-text-2 {
      width: auto;
      margin-top: 17px;
      margin-right: 360px;
      margin-left: 0;
    }
    }

    @media (max-width: 767px) {
      .u-dialog-section-4 .u-dialog-1 {
      width: 540px;
      min-height: 548px;
      margin-top: 124px;
    }

      .u-dialog-section-4 .u-container-layout-1 {
      padding-top: 45px;
      padding-left: 25px;
      padding-right: 25px;
    }

      .u-dialog-section-4 .u-image-1 {
      width: 170px;
      height: 464px;
    }

      .u-dialog-section-4 .u-text-1 {
      margin-top: -464px;
      margin-right: 190px;
    }

      .u-dialog-section-4 .u-text-2 {
      margin-top: 30px;
      margin-right: 190px;
    }
    }

    @media (max-width: 575px) {
      .u-dialog-section-4 .u-dialog-1 {
      width: 340px;
      min-height: 736px;
      margin-top: 33px;
      margin-bottom: 57px;
    }

      .u-dialog-section-4 .u-container-layout-1 {
      padding-left: 20px;
      padding-right: 20px;
    }

      .u-dialog-section-4 .u-image-1 {
      height: 174px;
      margin-right: initial;
      margin-left: initial;
      width: auto;
    }

      .u-dialog-section-4 .u-text-1 {
      margin-top: 27px;
      margin-right: 0;
    }

      .u-dialog-section-4 .u-text-2 {
      margin-right: 0;
    }
    }`}</style>
    <section
      className="u-align-center u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-valign-middle u-dialog-section-5"
      id="sec-8f81">
      <div className="u-container-style u-custom-color-1 u-dialog u-radius-13 u-shape-round u-dialog-1">
        <div className="u-container-layout u-container-layout-1"><span className="u-file-icon u-icon u-icon-1"><img
          src={transferIcon} alt=""/></span>
          <h2 className="u-align-center u-custom-font u-text u-text-default u-text-1">Transfer NFT</h2>
          <p className="u-align-center u-custom-font u-text u-text-2">Disclaimer: Only send your VNFT to a official
            VeChainThor network wallet like Sync 2 or VeChainThor Mobile wallet</p>
          <div className="u-form u-form-1">
            <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                 style={{padding: '10px'}}>
              <div className="u-form-group u-form-name">
                <label htmlFor="name-e7d3" className="u-label">VeChain Wallet Address</label>
                <input type="text" placeholder="Enter a valid VeChain wallet address" id="name-e7d3"
                       onChange={e => setRecipient(e.target.value)}
                       className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"/>
              </div>
              <div className="u-align-center u-form-group u-form-submit">
                <button className="u-btn u-btn-submit u-button-style"
                        onClick={async () => await nftContract.transfer(bananaCrackNftAbi, bananaCrackContract, recipient, metadata[transferModelData].result.edition, setLoading, loadAssets)}
                >Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="u-dialog-close-button u-icon u-text-grey-50 u-icon-2" onClick={() => closeModal('sec-8f81')}>
          <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 413 413">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-5801"></use>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
               xmlSpace="preserve" className="u-svg-content" viewBox="0 0 413 413" id="svg-5801">
            <path
              d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
          </svg>
        </button>
      </div>
    </section>
    <style> {`.u-dialog-section-5 {
    min - height: 826px;
  }

    .u-dialog-section-5 .u-dialog-1 {
      width: 800px;
      min-height: 534px;
      box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
      height: auto;
      margin: 60px auto;
    }

    .u-dialog-section-5 .u-container-layout-1 {
      padding: 40px 25px;
    }

    .u-dialog-section-5 .u-icon-1 {
      height: 64px;
      width: 64px;
      margin: 14px auto 0;
    }

    .u-dialog-section-5 .u-text-1 {
      font - size: 2.5rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: "Alfa Slab One";
      margin: 38px auto 0;
    }

    .u-dialog-section-5 .u-text-2 {
      font - family: "Akaya Kanadaka";
      margin: 38px 5px 0;
    }

    .u-dialog-section-5 .u-form-1 {
      height: 154px;
      width: 745px;
      margin: 38px 0 0 5px;
    }

    .u-dialog-section-5 .u-icon-2 {
      width: 15px;
      height: 15px;
      left: auto;
      top: 17px;
      position: absolute;
      right: 16px;
    }

    @media (max-width: 1199px) {
      .u-dialog-section-5 .u-icon-1 {
      margin-top: 0;
    }
    }

    @media (max-width: 991px) {
      .u-dialog-section-5 .u-dialog-1 {
      width: 720px;
    }

      .u-dialog-section-5 .u-container-layout-1 {
      padding-top: 30px;
      padding-bottom: 30px;
    }

      .u-dialog-section-5 .u-text-2 {
      margin-left: 0;
      margin-right: 0;
    }

      .u-dialog-section-5 .u-form-1 {
      width: 670px;
      margin-left: 0;
    }
    }

    @media (max-width: 767px) {
      .u-dialog-section-5 .u-dialog-1 {
      width: 540px;
    }

      .u-dialog-section-5 .u-container-layout-1 {
      padding-top: 45px;
      padding-left: 30px;
      padding-right: 30px;
    }

      .u-dialog-section-5 .u-form-1 {
      width: 520px;
    }
    }

    @media (max-width: 575px) {
      .u-dialog-section-5 .u-dialog-1 {
      width: 340px;
      min-height: 404px;
    }

      .u-dialog-section-5 .u-container-layout-1 {
      padding-top: 40px;
      padding-left: 25px;
      padding-right: 25px;
    }

      .u-dialog-section-5 .u-text-1 {
      font-size: 1.875rem;
      margin-top: 29px;
    }

      .u-dialog-section-5 .u-form-1 {
      width: 320px;
    }
    } `}</style>
  </div>


};

export default BananaCrack;
