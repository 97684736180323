import {useEffect, useRef, useState} from 'react';
import {NftContract} from "../components/functions/NftContract";
import {gangstaGorillasNftAbi} from "../constants/gangstaGorillasNftAbi";
import {gangstaGorillasContract} from "../constants/contract";

const Mint = () => {
  const [loading, setLoading] = useState(true);
  const totalSupply = useRef(0);
  const [availableTokenCount, setAvailableTokenCount] = useState(0);
  const currentMintPrice = useRef(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [nftContract] = useState(new NftContract());
  const saleInfo = useRef([]);
  const saleStarted = useRef(false);

  useEffect(async () => {
    await initialize();
  })

  const initialize = async () => {
    totalSupply.current = await nftContract.totalSupply(gangstaGorillasNftAbi, gangstaGorillasContract);
    saleStarted.current = await nftContract.saleStarted(gangstaGorillasNftAbi, gangstaGorillasContract);
    setAvailableTokenCount(await nftContract.availableTokenCount(gangstaGorillasNftAbi, gangstaGorillasContract));
    saleInfo.current = await nftContract.saleInfo(gangstaGorillasNftAbi, gangstaGorillasContract);
    currentMintPrice.current = (parseInt(saleInfo.current['price']));

    if (saleInfo.current['saleType'] !== '2') {
      setMaxMintAmount(await nftContract.whitelistMintAmount(gangstaGorillasNftAbi, gangstaGorillasContract));
    } else {
      setMaxMintAmount(saleInfo.current['maxTokens']);
    }
  }

  const mint = async () => {
    console.log(currentMintPrice.current);
    await nftContract.mint(gangstaGorillasNftAbi, gangstaGorillasContract, currentMintPrice.current, mintAmount, setLoading, initialize)
  }

  const handleMintAmount = (amount) => {
    if (amount > maxMintAmount) {
      setMintAmount(maxMintAmount);
    } else {
      setMintAmount(amount);
    }
  }

  import('../Mint-your-GG.css')
    return <div>
      <section className="u-clearfix u-custom-color-1 u-section-1" id="sec-4a4c" style={{ paddingTop: '187px'}}>
        <div className="u-clearfix u-sheet u-sheet-1">
          <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
            <div className="u-layout">
              <div className="u-layout-row">
                <div
                  className="u-align-center u-container-style u-custom-color-1 u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-layout-cell-1"
                  src="">
                  <div className="u-container-layout u-container-layout-1">
                    <h2 className="u-custom-font u-text u-text-1">Mint your Gangster Gorilla.</h2>
                    <p className="u-custom-font u-text u-text-2">Gorillaz minted : {totalSupply.current - availableTokenCount} / {totalSupply.current}</p>
                    <p className="u-custom-font u-text u-text-3">
                      Early access:<br />30th of August 2022 at 10:00 am UTC+0<br />
                      The presale:<br />30th of August 2022 at 12:00 pm UTC +0<br />
                      The public:<br />30th of August 2022 at 18:00 pm UTC +0
                    </p>
                    <p className="u-custom-font u-text u-text-4">
                      Early Access: 150 VET<br />
                      Pre-Sale (White Listed): 200 VET<br />
                      Public sale 250 VET
                  </p>
                    <p className="u-custom-font u-text u-text-5">
                      Early Access: Max 10 NFTs per wallet<br />
                      Whitelist : Max 5 NFTs per wallet<br />
                      Public sale : Max 10 NFTS per TX
                    </p>
                    {saleStarted ?
                      <>
                        {maxMintAmount > 0 ?
                          <>
                            {/*<h6 className="u-custom-font u-text u-text-6">Amount you like to mint</h6>*/}
                            {/*<input type="number" max={maxMintAmount} min={1} value={mintAmount} onChange={num=>handleMintAmount(Number(num.target.value))} className="u-border-2 u-border-grey-dark-1 u-shape u-shape-rectangle u-white u-shape-1" />*/}
                            {/*<button onClick={mint} className="u-border-2 u-border-hover-grey-75 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-1">Mint</button>*/}
                          </> :
                          <p>
                            You have reached the maximum amount of NFTs you can mint / or you are not whitelisted.
                          </p>
                        }
                      </>
                      :
                      <>
                        <p>
                          The presale is not yet started.
                        </p>
                      </>
                    }
                    <p className="u-custom-font u-text u-text-8">After signing your TX. you can find your Gorilla
                      Gangster NFT in the "<a href="Your-Gorillaz.html" data-page-id="887420855"
                                              className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">My
                        Gorillaz</a>" Tab in the menu.
                    </p>
                  </div>
                </div>
                <div
                  className="u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-1"
                  data-image-width="1600" data-image-height="2458">
                  <div className="u-container-layout u-valign-middle u-container-layout-2" src=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
};

export default Mint;
