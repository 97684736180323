import roadMap from '../images/Roadmap.png'
import tokenomics from '../images/rahlguirugh.png'

const LearnMore = () => {

  import('./../Learn-More.css')
  return <div>
      <section className="u-align-center u-clearfix u-custom-color-1 u-section-1" id="sec-6de0"  style={{paddingTop: '187px'}}>
        <div className="u-clearfix u-sheet u-sheet-1">
          <h2 className="u-custom-font u-text u-text-default u-text-1">KEY BENEFITS</h2>
          <p className="u-text u-text-2">Here we will explain some Key Benefits from the Gangster Gorilla NFT
            Collection. You will also be able to find our Roadmap &amp; BANANAnomics on this Page</p>
          <div className="u-expanded-width u-list u-list-1">
            <div className="u-repeater u-repeater-1">
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-1"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-1"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-5b74"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-5b74"><path
                  d="m40.6 11c2.3 0 4.4 0.9 6.1 2.5 5.4 5.4 10.8 19.2 8.9 21.2 0 0-0.3 0.3-1.5 0.3-5.5 0-16.1-5.7-19.5-9.2-3.4-3.4-3.4-8.9 0-12.2 1.5-1.7 3.7-2.6 6-2.6m46.8 0.1c2.2 0 4.4 0.8 6.1 2.4 3.4 3.4 3.4 8.9 0 12.2-3.4 3.5-14 9.2-19.5 9.2-1.1 0-1.5-0.3-1.5-0.3-1.9-1.9 3.5-15.7 8.9-21.2 1.6-1.5 3.8-2.3 6-2.3m32.6 32.1v16h-52v-16h52m-60 0v16h-52v-16h52m52 23.9v47.9h-44v-47.9h44m-52 0v47.9h-44v-47.9h44m-19.4-64.1c-4.4 0-8.6 1.7-11.7 4.9-6.5 6.5-6.5 17 0 23.5 1.2 1.2 2.8 2.5 4.7 3.8h-25.6c-4.4 0-8 3.6-8 8v24h8v55.8h112v-55.9h8v-24c0-4.4-3.6-8-8-8h-25.6c1.9-1.3 3.6-2.6 4.7-3.8 6.5-6.5 6.5-17 0-23.5-3-3-7.3-4.8-11.7-4.8s-8.7 1.7-11.7 4.8c-4.6 4.6-7.8 11.7-8.9 14.5-2.2 5.4-3 9.5-2.5 12.8h-0.5c0.5-3.2-0.4-7.4-2.5-12.8-1.2-2.8-4.3-9.9-8.9-14.5-3.2-3.1-7.4-4.8-11.8-4.8z"></path></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-3">Airdrops &amp; Events</h3>
                  <p className="u-align-center u-text u-text-4">Gangster Gorillaz are here to stay, They will continue
                    to build their brand by providing the community with Free Mints, Airdrops, Events and more!</p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-2"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-2"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-1ee0"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-1ee0"><path
                  d="m44.1 63.9h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c0-5.6-2.8-10.7-7.3-13.3-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-6.9 4.6-11.3-0.1-9.2-7.6-16.6-16.8-16.6s-16.7 7.4-16.7 16.5c0 4.4 1.7 8.4 4.6 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.5 2.7-7.3 7.7-7.3 13.3v1.8c-0.1 4.9 3.7 8.9 8.3 8.9zm19.9-48c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm-20.3 37.3c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8zm5.5 40.9c-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-7 4.6-11.3 0-9.1-7.5-16.5-16.7-16.5s-16.7 7.4-16.7 16.5c0 4.4 1.7 8.4 4.6 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.6 2.6-7.4 7.7-7.4 13.3v1.8c0 4.9 3.8 8.8 8.4 8.8h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c0-5.6-2.8-10.7-7.4-13.3zm-20.9-24.1c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm20.3 39.2c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8zm72.1-15.1c-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-6.9 4.6-11.3 0-9.1-7.5-16.5-16.7-16.5s-16.7 7.4-16.7 16.5c-0.1 4.3 1.7 8.3 4.5 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.5 2.7-7.3 7.7-7.3 13.3v1.8c0 4.9 3.8 8.8 8.4 8.8h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c-0.1-5.6-2.9-10.7-7.4-13.3zm-21-24.1c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm20.3 39.2c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8z"></path></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-5">Community</h3>
                  <p className="u-align-center u-text u-text-6">By keeping in touch with their community. They are
                    building a real Bond with their investors. There are lots of community engaging Events and more!</p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-3"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-3"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-1a31"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-1a31"><polygon
                  points="128 3 17 3 17 24 25 24 25 11 120 11 120 24 111 24 111 32 120 32 120 95 111 95 111 103 128 103"></polygon><path
                  d="m25 24h-25v100h111v-100h-86zm78 92h-95v-63h95v63zm0-71h-95v-13h95v13z"></path><path
                  d="m46 74v21h-21v-21h21m8-8h-37v37h37v-37z"></path><rect x="62" y="66" width="32" height="8"></rect><rect
                  x="62" y="81" width="32" height="8"></rect><rect x="62" y="95" width="32" height="8"></rect></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-7">5000&nbsp; NFTs</h3>
                  <p className="u-align-center u-text u-text-8">Our Genisis Collection on VeChain will hold 5000
                    Gorillaz. These Gorillaz will provide you with access to other Chains &amp; Communities. Are you
                    ready to show the world what VeFam is about?</p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-4"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-4"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-9646"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-9646"><path
                  d="m119.4 104.8-17.8-30.8c1.6-1.3 2.5-3.4 2.4-5.6l-1.3-10.7 7.4-7.6c2.3-2.3 2.4-6.1 0.2-8.8l-7.4-7.9 1.5-10.8c0.3-3.2-2-6.4-5.2-7l-10.7-2.1-5.2-9.4c-1.1-2.2-3.3-3.5-5.8-3.5-0.8 0-1.5 0.1-2.3 0.4l-10.4 4.4-9.6-4.6c-0.9-0.5-1.9-0.7-3-0.7-0.6 0-1.3 0.1-1.9 0.3-1.6 0.5-2.9 1.5-3.8 3l-5.3 9.4-10.7 1.9c-3.4 0.6-5.7 3.7-5.4 7.1l1.3 10.7-7.4 7.6c-2.3 2.3-2.4 6.1-0.2 8.8l7.4 7.9-1.5 10.8c-0.3 2.3 0.8 4.6 2.7 5.9l-17.9 31.3c-0.7 1.1-0.7 2.6-0.1 3.9v0.1l0.1 0.1c0.8 1.2 2 1.9 3.4 1.9h19.2l8.7 14.9c0.8 1.2 2 1.9 3.4 1.9s2.8-0.8 3.5-2l16.6-27.9 16.8 28.3c0.8 1.2 2 1.9 3.4 1.9s2.8-0.8 3.5-2l8.7-15.3h19.2c1.4 0 2.7-0.8 3.4-1.9 0.9-1.1 0.9-2.7 0.1-3.9zm-34.9 11.3-17.9-30.5 7.4 3.6c0.9 0.4 1.8 0.6 2.9 0.6 2.4 0 4.6-1.2 5.7-3.2l5.3-9.4 5.8-1 15.4 26.6h-14.7c-1.4 0-2.8 0.8-3.5 2l-6.4 11.3zm-40-0.3-6.5-11c-0.8-1.2-2-1.9-3.4-1.9h-14.8l15.5-27 5.4 0.9 5.2 9.3c1.1 2.2 3.3 3.5 5.8 3.5 0.8 0 1.5-0.1 2.3-0.4l0.5-0.1 3.9-1.8 1.4 2.4-15.3 26.1zm-11.9-78.1c1.4-1.4 2.1-3.4 1.8-5.3l-1.2-10 10.2-1.8c1.9-0.3 3.6-1.6 4.6-3.2l4.9-8.8 9 4.3c0.9 0.5 1.9 0.7 2.9 0.7s2-0.2 2.9-0.7l9.2-4.1 4.9 9.1c1.1 1.7 2.7 2.9 4.5 3.1l10 1.9-1.4 10c-0.3 1.9 0.3 4 1.7 5.3l6.9 7.3-7 7.1c-1.5 1.4-2.1 3.4-1.8 5.3l1.2 9.9-9.9 1.6c-2 0.3-3.7 1.6-4.6 3.2l-4.9 8.8-9-4.3c-0.9-0.5-1.9-0.7-2.9-0.7s-2 0.2-2.9 0.7l-9.2 4.2-4.9-9c-1.1-1.7-2.8-2.9-4.6-3.1l-10.1-1.8 1.4-10c0.3-1.9-0.3-4-1.7-5.3l-6.8-7.4 6.8-7z"></path><path
                  d="m64.5 71c14.7 0 26.6-11.7 26.6-26.2 0-14.4-11.9-26.2-26.6-26.2s-26.6 11.7-26.6 26.2 11.9 26.2 26.6 26.2zm0-44.4c10.2 0 18.6 8.2 18.6 18.3s-8.3 18.3-18.6 18.3c-10.2 0-18.6-8.2-18.6-18.3s8.4-18.3 18.6-18.3z"></path></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-9">Top Ranks</h3>
                  <p className="u-align-center u-text u-text-10">The Ranks and Rarity Scores of Gorillaz will be
                    calculated by&nbsp;NftRarityTool.eu !<br />We have 15 Unique Gorillaz that will form the top 15. The
                      rest of the Gorillaz will be Calculated Trait wise.
                  </p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-5"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-5"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-760d"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-760d"><path
                  d="m117.4 76c1.5 0 2.7 1.2 2.7 2.7v38.7c0 1.5-1.2 2.7-2.7 2.7h-38.7c-1.5 0-2.7-1.2-2.7-2.7v-38.7c0-1.5 1.2-2.7 2.7-2.7h38.7m0-7.9h-38.7c-5.8 0-10.6 4.8-10.6 10.6v38.7c0 5.8 4.8 10.6 10.6 10.6h38.7c5.8 0 10.6-4.8 10.6-10.6v-38.7c0-5.8-4.8-10.6-10.6-10.6z"></path><path
                  d="m49.3 7.9c1.5 0 2.7 1.2 2.7 2.7v38.7c0 1.5-1.2 2.7-2.7 2.7h-38.7c-1.5 0-2.7-1.2-2.7-2.7v-38.7c0-1.5 1.2-2.7 2.7-2.7h38.7m0-7.9h-38.7c-5.8 0-10.6 4.8-10.6 10.6v38.7c0 5.8 4.8 10.6 10.6 10.6h38.7c5.8 0 10.6-4.8 10.6-10.6v-38.7c0-5.8-4.8-10.6-10.6-10.6z"></path><path
                  d="m90 7.9c0.7 0 1.4 0.3 1.9 0.8l27.4 27.4c1 1 1 2.7 0 3.8l-27.4 27.3c-0.5 0.5-1.2 0.8-1.9 0.8s-1.4-0.3-1.9-0.8l-27.3-27.3c-1-1-1-2.7 0-3.8l27.3-27.4c0.6-0.5 1.3-0.8 1.9-0.8m0-7.9c-2.8 0-5.5 1.1-7.5 3.1l-27.3 27.4c-2 2-3.1 4.7-3.1 7.5s1.1 5.5 3.1 7.5l27.4 27.4c2 2 4.7 3.1 7.5 3.1s5.5-1.1 7.5-3.1l27.4-27.4c2-2 3.1-4.7 3.1-7.5s-1.1-5.5-3.1-7.5l-27.5-27.4c-2-2-4.6-3.1-7.5-3.1z"></path><path
                  d="m49.3 76c1.5 0 2.7 1.2 2.7 2.7v38.7c0 1.5-1.2 2.7-2.7 2.7h-38.7c-1.5 0-2.7-1.2-2.7-2.7v-38.7c0-1.5 1.2-2.7 2.7-2.7h38.7m0-7.9h-38.7c-5.8 0-10.6 4.8-10.6 10.6v38.7c0 5.8 4.8 10.6 10.6 10.6h38.7c5.8 0 10.6-4.8 10.6-10.6v-38.7c0-5.8-4.8-10.6-10.6-10.6z"></path></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-11">Artist</h3>
                  <p className="u-align-center u-text u-text-12">The Artist of Gangster Gorillaz is a wel known artist
                    within the space. We are proud to say FingaBanga420 is our Leading artist!</p>
                </div>
              </div>
              <div className="u-align-center u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-container-layout-6"><span
                  className="u-custom-color-3 u-icon u-icon-circle u-icon-6"><svg className="u-svg-link"
                                                                                  preserveAspectRatio="xMidYMin slice"
                                                                                  viewBox="0 0 128 128" ><use
                  xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-064f"></use></svg><svg
                  className="u-svg-content" viewBox="0 0 128 128" id="svg-064f"><path
                  d="m102.7 39.2c0.8 0 1.5 0.3 2.1 0.9l7.8 7.8c4.8 4.8 7.7 11.5 7.4 18.3-0.6 12.6-11 22.6-23.7 22.6h-29c-0.1 0-0.1-0.1-0.1-0.2 1.1-3 4.1-5.2 7.5-5.2h10.5c0.9 0 1.8-0.4 2.3-1.1 0.8-1.1 0.7-2.6-0.3-3.5l-24.9-24.8c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.5 0.9-2.1 1.3-0.8 2.1-0.8 1.5 0.3 2.1 0.8l16.9 16.9c0.5 0.5 1.2 0.8 1.9 0.8s1.4-0.3 1.9-0.8 0.8-1.2 0.8-1.9-0.3-1.4-0.8-1.9l-6.7-6.7c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.5 0.9-2.1 1.3-0.8 2.1-0.8 1.5 0.3 2.1 0.8l4 4c0.5 0.5 1.2 0.8 1.9 0.8s1.4-0.3 1.9-0.8 0.8-1.2 0.8-1.9-0.3-1.4-0.8-1.9l-2.7-2.7c-0.5-0.5-0.8-1.2-0.8-2s0.3-1.5 0.9-2.1 1.3-0.9 2.1-0.9 1.5 0.3 2.1 0.9l2.7 2.7c0.5 0.5 1.2 0.8 1.9 0.8s1.4-0.3 1.9-0.8 0.8-1.2 0.8-1.9-0.2-1.3-0.7-1.8c-0.8-0.9-1.2-2.1-0.8-3.3 0.5-1.3 1.6-1.9 2.8-1.9m0-8c-2.9 0-5.7 1.1-7.8 3.2-0.7 0.7-1.2 1.4-1.7 2.1 0 0-0.1 0.1-0.1 0-0.4 0-0.8-0.1-1.1-0.1-2.9 0-5.7 1.1-7.8 3.2-1 1-1.8 2.2-2.3 3.4 0 0 0 0.1-0.1 0.1-2.6 0.2-5.1 1.3-6.9 3.2l-0.2 0.2-2.5-2.5c-2-2-4.9-3.2-7.8-3.2s-5.7 1.1-7.8 3.2-3.2 4.9-3.2 7.8c0 3 1.2 5.7 3.2 7.8l15.8 15.8c0.1 0.1 0 0.2-0.1 0.2-5.8 0.8-10.8 4.8-12.8 10.5-1.9 5.2 2 10.7 7.5 10.7h29.1c8.5 0 16.4-3.3 22.4-9.3 6.1-6.1 9.4-14.3 9.3-23-0.1-8.4-3.8-16.4-9.8-22.4l-7.8-7.8c-1.8-2-4.6-3.1-7.5-3.1z"></path><path
                  d="m82.1 118c0 1.1-0.9 2-2 2h-70.2c-1.1 0-2-0.9-2-2v-108c0-1.1 0.9-2 2-2h70.1c1.1 0 2 0.9 2 2v16.9c0 2.2 1.8 4 4 4s4-1.8 4-4v-16.9c0-5.5-4.5-10-9.9-10h-70.2c-5.4 0-9.9 4.5-9.9 10v108c0 5.5 4.5 10 9.9 10h70.1c5.5 0 9.9-4.5 9.9-10v-10.6c0-2.2-1.8-4-4-4s-4 1.8-4 4v10.6z"></path></svg>


          </span>
                  <h3 className="u-align-center u-text u-text-13">Sell &amp; Buy</h3>
                  <p className="u-align-center u-text u-text-14">After Mint you will be able to trade your Gangster
                    Gorillaz on several Marketplaces. Such as World of V and BlackVeMarket 2.0.<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="u-align-center u-clearfix u-custom-color-4 u-section-2" id="sec-3cdc">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div
                className="u-align-left u-container-style u-layout-cell u-left-cell u-size-31 u-size-xs-60 u-layout-cell-1"
                src="">
                <div className="u-container-layout u-valign-middle u-container-layout-1" src="">
                  <img className="u-image u-image-1" src={roadMap} data-image-width="1500"
                       data-image-height="1500" />
                </div>
              </div>
              <div
                className="u-align-center u-container-style u-custom-color-2 u-layout-cell u-right-cell u-size-29 u-size-xs-60 u-layout-cell-2">
                <div className="u-container-layout u-container-layout-2">
                  <h2 className="u-custom-font u-text u-text-default u-text-1">ROADMAP</h2>
                  <p className="u-text u-text-2">As you can see we are planning to Build a massive brand with Gangster
                    Gorillaz. Let us explain some important points.<br />
                      <br />
                        <span style={{fontWeight: '700'}}>MINT DAY.</span>
                        <br />
                          <br />Minting will start on the 30th of August.<br />Presale will start 12 PM UTC<br />Public Sale
                            will start 6 PM UTC<br />
                              <br />
                                <span style={{fontWeight: '700'}}>CONCRETE JUNGLE.</span>
                                <br />
                                  <br />Maybe ya'll saw them passing by on twitter.. But Every 25 Gangster Gorillaz will
                                    get a free Concrete Jungle Airdrop. Straight in their Wallet. This NFT Represents a
                                    PLOT in our BANANA-Verse<br />
                                      <br />
                                        <span style={{fontWeight: '700'}}>CROSSCHAIN.</span>
                                        <br />
                                          <br />One of the ways we are planning to reward our holders with are FREE Mints
                                            on other BlockChains. We are looking in to mint on 6 Different Blockchains!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-gradient u-section-3" id="sec-ef19">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-row">
              <div className="u-size-30 u-size-60-md">
                <div className="u-layout-row">
                  <div
                    className="u-align-center u-container-style u-custom-color-2 u-layout-cell u-right-cell u-size-60 u-layout-cell-1">
                    <div className="u-container-layout u-container-layout-1">
                      <h2 className="u-custom-font u-text u-text-1">CONCRETE JUNGLES</h2>
                      <p className="u-text u-text-2">A huge part of Gorilla Gangsterz future. Will circle around their
                        own plots of land called Concrete Jungles.<br />
                          <br />You will be able to Claim a Plot of land inside our BANANA-Verse.<br />
                            <br />Minting 25 Gorillaz grant you 1 Random Selected Concrete Jungle Plot.<br />
                              <br />Upgrade your PLOT to gain more Rewards When you start using your Concrete Jungle Plot.<br />
                                <br />Disclaimer: Visuals are Concept art. These can still change towards the actual
                                  release.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-size-30 u-size-60-md">
                <div className="u-layout-col">
                  <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-20 u-image-1"
                       data-image-width="1920" data-image-height="1080">
                    <div className="u-container-layout u-valign-middle u-container-layout-2"></div>
                  </div>
                  <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-40 u-image-2"
                       data-image-width="1920" data-image-height="1080">
                    <div className="u-container-layout u-valign-middle u-container-layout-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="u-clearfix u-custom-color-1 u-section-4" id="sec-fb27">
      <div className="u-clearfix u-sheet u-sheet-1">
        <img className="u-border-2 u-border-custom-color-2 u-image u-image-default u-image-1"
             src={tokenomics} alt="" data-image-width="951" data-image-height="532"/>
          <p
            className="u-custom-font u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xl u-text-1">BANANANOMICS</p>
          <h5 className="u-align-center u-custom-font u-font-open-sans u-text u-text-default u-text-2">Our own Token
            will be used for Upgrading your Gorilla and or Concrete Jungle.<br />
              <br />As you can see on our Roadmap we will start narrowing down the collections in Phase 3.<br />Bringing
                Mutant &amp; High Life Gorillaz to VeChain.<br />
                  <br />Concrete Jungle Upgrades will include Higher Staking Rewards, A possible Upgrade of Plot Size and
                    more!<br />
                      <br />All $BANANA Tokens we Receive back during Upgrades. Will be burned Making the total supply of
                        $BANANA Smaller &amp; Smaller.<br />
          </h5>
      </div>
    </section>



    </div>
    ;
}

export default LearnMore;
