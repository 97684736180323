import { PROJECT_NAME, USER_ADDRESS, ADDRESS_KEY } from "../../constants";
import {connex} from "../../constants/contract"
import {useEffect, useState} from "react";
import {findMethodABI} from "../../utilities/util";
import titleLogo from '../../images/Untitled251_20220715095927.png'

const Header = () => {
    // const [dragonCoinBalance, setDragonCoinBalance] = useState(null)

    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    useEffect(async () => {

        if (USER_ADDRESS) {
            // const dragonCoinBalanceAbi = findMethodABI(dragonCoinAbi, 'balanceOf')
            // const dragonCoinBalanceCall = await dragonCoinContract.method(dragonCoinBalanceAbi).call(USER_ADDRESS);
            // const balance = parseInt(dragonCoinBalanceCall.decoded[0])
            // setDragonCoinBalance(balance / ("1e" + 18))
        }
    })

    const handleLogout = () => {
        localStorage.removeItem(ADDRESS_KEY)
        document.location.href = "/";
    }

    return <header className="u-clearfix u-custom-color-2 u-header u-header" id="sec-058a">
      <div className="u-clearfix u-sheet u-sheet-1">
        <a href="/" data-page-id="1309611344" className="u-image u-logo u-image-1" data-image-width="1280"
           data-image-height="1280" title="Home">
          <img src={titleLogo} className="u-logo-image u-logo-image-1" />
        </a>
        <nav className="u-menu u-menu-dropdown u-menu-open-right u-offcanvas u-menu-1" data-responsive-from="XL">
          <div className="menu-collapse" style={{fontSize: '1rem', letterSpacing: '0px'}}>
            <a
              className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
              href="#" style={{ fontSize: 'calc(1em + 42px)' }}>
              <svg className="u-svg-link" viewBox="0 0 24 24">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu-hamburger"></use>
              </svg>
              <svg className="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" x="0px" y="0px"
                   xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <rect y="1" width="16" height="2"></rect>
                  <rect y="7" width="16" height="2"></rect>
                  <rect y="13" width="16" height="2"></rect>
                </g>
              </svg>
            </a>
          </div>
          <div className="u-custom-menu u-nav-container">
            <ul className="u-nav u-unstyled u-nav-1">
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/"
                sstyle={{padding: '10px 20px'}}>Home</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/learn-more"
                style={{padding: '10px 20px'}}>Learn More</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/mint"
                style={{padding: '10px 20px'}}>Mint your GG</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/assets"
                style={{padding: '10px 20px'}}>Your Gorillaz</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/bananacrack"
                style={{padding: '10px 20px'}}>Your Banana Crack</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/mint-concretejungle"
                style={{padding: '10px 20px'}}>Mint Concrete Jungle</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/emptyplots-assets"
                style={{padding: '10px 20px'}}>Your empty plots</a>
              </li>
              <li className="u-nav-item"><a
                className="u-button-style u-nav-link u-text-active-black u-text-hover-grey-50" href="/building-assets"
                style={{padding: '10px 20px'}}>your buildings</a>
              </li>
            </ul>
          </div>
          <div className="u-custom-menu u-nav-container-collapse">
            <div
              className="u-container-style u-custom-color-2 u-inner-container-layout u-opacity u-opacity-75 u-sidenav">
              <div className="u-inner-container-layout u-sidenav-overflow">
                <div className="u-menu-close"></div>
                <ul className="u-align-center u-custom-font u-nav u-popupmenu-items u-spacing-0 u-unstyled u-nav-2">
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/">Home</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/learn-more">Learn
                    More</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/mint">Mint your
                    GG</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/assets">Your
                    Gorillaz</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/bananacrack">Your
                    bananacrack</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/mint-concretejungle">
                    mint concrete jungle</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/emptyplots-assets">Your
                    empty plots</a>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link" href="/building-assets">Your
                    buildings</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="u-custom-color-1 u-menu-overlay u-opacity u-opacity-70"></div>
          </div>
        </nav>
        {USER_ADDRESS === null ?
        <button onClick={handleLogIn} className="u-border-2 u-border-hover-grey-80 u-border-white u-btn u-button-style u-custom-font u-dialog-link u-none u-text-hover-white u-btn-1">Connect<br />Wallet</button> :
        <button onClick={handleLogout} className="u-border-2 u-border-hover-grey-80 u-border-white u-btn u-button-style u-custom-font u-dialog-link u-none u-text-hover-white u-btn-1">{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)} <br /> Logout</button>}
      </div>
    </header>
}

export default Header
