import Connex from '@vechain/connex'
import {
  NODE,
  NETWORK,
  GG_CONTRACT,
  BC_CONTRACT,
  CJ_MINT_CONTRACT,
  EMPTY_PLOT_CONTRACT,
  BUILDING_CONTRACT,
} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const gangstaGorillasContract = connex.thor.account(GG_CONTRACT)
const bananaCrackContract = connex.thor.account(BC_CONTRACT)
const cjMintContract = connex.thor.account(CJ_MINT_CONTRACT)
const emptyPlotContract = connex.thor.account(EMPTY_PLOT_CONTRACT)
const buildingContract = connex.thor.account(BUILDING_CONTRACT)

export {
    connex,
    gangstaGorillasContract,
    bananaCrackContract,
    cjMintContract,
    emptyPlotContract,
    buildingContract
}
