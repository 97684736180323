import {useEffect, useRef, useState} from 'react';
import {NftContract} from "../components/functions/NftContract";
import {concreteJungleMintAbi} from "../constants/concreteJungleMintAbi";
import {cjMintContract} from "../constants/contract";

const MintConcreteJungle = () => {
  const [loading, setLoading] = useState(true);
  const totalSupply = useRef(0);
  const [availableTokenCount, setAvailableTokenCount] = useState(0);
  const currentMintPrice = useRef(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [nftContract] = useState(new NftContract());
  const saleInfo = useRef([]);
  const saleStarted = useRef(false);

  useEffect(async () => {
    await initialize();
  })

  const initialize = async () => {
    // totalSupply.current = await nftContract.totalSupply(concreteJungleMintAbi, cjMintContract);
    saleStarted.current = await nftContract.saleStarted(concreteJungleMintAbi, cjMintContract);
    setAvailableTokenCount(await nftContract.getAvailableTokenCount(concreteJungleMintAbi, cjMintContract));
    saleInfo.current = await nftContract.saleInfo(concreteJungleMintAbi, cjMintContract);
    currentMintPrice.current = (parseInt(saleInfo.current['price']));

    console.log(saleInfo.current)
    if (saleInfo.current['saleType'] !== '1') {
      setMaxMintAmount(await nftContract.whitelistMintAmount(concreteJungleMintAbi, cjMintContract));
    } else {
      setMaxMintAmount(saleInfo.current['maxTokens']);
    }
  }

  const mint = async () => {
    await nftContract.mint(concreteJungleMintAbi, cjMintContract, currentMintPrice.current, mintAmount, setLoading, initialize)
  }

  const handleMintAmount = (amount) => {
    if (amount > maxMintAmount) {
      setMintAmount(maxMintAmount);
    } else {
      setMintAmount(amount);
    }
  }

  import('../Mint-your-Concrete-Jungle.css')
  return <div>
    <section className="u-clearfix u-custom-color-1 u-section-1" id="sec-4a4c" style={{ paddingTop: '187px'}}>
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div
                className="u-align-center u-container-style u-custom-color-1 u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-layout-cell-1"
                src="">
                <div className="u-container-layout u-container-layout-1">
                  <h2 className="u-custom-font u-text u-text-1">Mint your Concrete Jungle.</h2>
                  <p className="u-custom-font u-text u-text-2">Jungles minted : {availableTokenCount} / 500</p>
                  <p className="u-custom-font u-text u-text-3">
                    The presale:<br /> 31st of October 2022 at 12:00 pm UTC +0<br />
                    The public:<br />31st of October 2022 at 03:00 pm UTC +0
                  </p>
                  <p className="u-custom-font u-text u-text-4">
                    Price Pre-Sale ( White Listed ) 1250 VET <br />
                    Price Public sale 1750 VET
                  </p>
                  <p className="u-custom-font u-text u-text-5">
                    Whitelist : Max 3 Mints per wallet<br />
                    Public sale : 1 Mint per TX
                  </p>
                  {saleStarted ?
                    <>
                      {maxMintAmount > 0 ?
                        <>
                          <h6 className="u-custom-font u-text u-text-6">Amount you like to mint</h6>
                          <input type="number" max={maxMintAmount} min={1} value={mintAmount} onChange={num=>handleMintAmount(Number(num.target.value))} className="u-border-2 u-border-grey-dark-1 u-shape u-shape-rectangle u-white u-shape-1" />
                          <button onClick={mint} className="u-border-2 u-border-hover-grey-75 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-1">Mint</button>
                        </> :
                        <p>
                          You have reached the maximum amount of NFTs you can mint / or you are not whitelisted.
                        </p>
                      }
                    </>
                    :
                    <>
                      <p>
                        The presale is not yet started.
                      </p>
                    </>
                  }
                </div>
              </div>
              <div
                className="u-align-center u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-1"
                data-image-width="1600" data-image-height="2458">
                <div className="u-container-layout u-valign-middle u-container-layout-2" src=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
};

export default MintConcreteJungle;
