import bananaBalance from '../images/Bananan.png'
import discordLogo from '../images/5968898.png'
import twitterLogo from '../images/733635.png'
import instagramLogo from '../images/1077093.png'
const Home = () => {

  import('./../Home.css')
  return <div>
    <section className="u-clearfix u-image u-shading u-section-1" id="sec-9264" data-image-width="1162"
             data-image-height="614">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-border-2 u-border-white u-container-style u-group u-group-1">
          <div className="u-container-layout u-container-layout-1">
            <h1 className="u-custom-font u-text u-title u-text-1">Welcome</h1>
            <p className="u-custom-font u-large-text u-text u-text-variant u-text-2">
              <span style={{fontSize: '1.875rem'}}>to the</span>
              <br />
                <span style={{fontSize: '3.75rem'}}>Concrete Jungle</span>
            </p>
          </div>
        </div>
        <a href="/learn-more" data-page-id="65845414"
           className="u-border-2 u-border-hover-grey-80 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-1">Learn
          more</a>
        <a href="/mint" data-page-id="1309611344"
           className="u-border-2 u-border-hover-grey-80 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-2">Minting</a>
        <a href="/assets" data-page-id="887420855"
           className="u-border-2 u-border-hover-grey-80 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-3">your
          Assets</a>
      </div>
    </section>
    {/*<section className="u-align-center u-clearfix u-custom-color-2 u-section-2" id="sec-94f2">*/}
    {/*  <div className="u-clearfix u-sheet u-sheet-1">*/}
    {/*    <h2 className="u-custom-font u-text u-text-1">$BANANA<br />BALANCE*/}
    {/*    </h2>*/}
    {/*    <h5 className="u-custom-font u-text u-text-2">Your $BANANA : 1500</h5>*/}
    {/*    <img className="u-image u-image-default u-preserve-proportions u-image-1" src={bananaBalance} alt=""*/}
    {/*         data-image-width="256" data-image-height="256" />*/}
    {/*      <a href="#" className="u-btn u-button-style u-palette-2-base u-btn-1">Transfer</a>*/}
    {/*  </div>*/}
    {/*</section>*/}
    <section className="u-clearfix u-image u-section-3" id="sec-dd58" data-image-width="1162" data-image-height="614">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div
          className="u-align-center u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-white u-group-1">
          <div className="u-container-layout u-container-layout-1">
            <h1 className="u-custom-font u-text u-title u-text-1">About us</h1>
            <p className="u-custom-font u-large-text u-text u-text-variant u-text-2"> We started out as a no nonsense
              project without roadmap, utility or other usecases as a PFP.<br />
                <br />This view on the project changed when the community started growing. Let me tell a thing or 2 about
                  Gangster Gorillaz.<br />
                    <br />Our ambitions grew beyond VeChain during the growth of the Project. This means we will launch a
                      total of 6 Collections on 6 different Block Chains. Every chain will have his own color of
                      Gangster Gorilla. The 6th Drop of Gangster Gorillaz will be on the Etheruem Blockchain. They will
                      contain all past colors, that is why we love to call them the rainbow series.<br />
                        <br />The first collection of 5000 Gangster Gorillaz will be on the VeChainThor BlockChain. <br />
                          <br />Holders of 5 Genisis VeChain GG NFTs will be rewarded with 1 free mint on every chain.
                            This will be stackable.<br />
                              <br />Holders of 10 GG NFTs will get 2 free mints.<br />Holders of 15 GG NFTs will get 3 free
                                mints.<br />And so on...
            </p>
            <a href="https://discord.gg/5ekNMJbc9V" className="u-btn u-button-style u-custom-font u-btn-1"
               target="_blank"><span className="u-file-icon u-icon"><img src={discordLogo}
                                                                         alt="" /></span>&nbsp;Discord
            </a>
            <a href="https://twitter.com/GangGorillaz" className="u-btn u-button-style u-custom-font u-btn-2"
               target="_blank"><span className="u-file-icon u-icon"><img src={twitterLogo}
                                                                         alt="" /></span>&nbsp;Twitter
            </a>
            <a href="https://www.instagram.com/gangstergorillaz/" className="u-btn u-button-style u-custom-font u-btn-3"
               target="_blank"><span className="u-file-icon u-icon"><img src={instagramLogo}
                                                                         alt="" /></span>&nbsp;Instagram
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="u-align-center u-clearfix u-custom-color-3 u-section-4" id="sec-d957">
      <div className="u-clearfix u-sheet u-sheet-1">
        <h2 className="u-custom-font u-text u-text-default u-text-1">Frequently Asked Questions</h2>
        <div className="u-border-3 u-border-grey-dark-1 u-line u-line-horizontal u-line-1"></div>
        <div className="u-accordion u-faq u-spacing-10 u-accordion-1">
          <div className="u-accordion-item">
            <a
              className="active u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-1"
              id="link-accordion-ae6a" aria-controls="accordion-ae6a" aria-selected="true">
              <span className="u-accordion-link-text"> What is an NFT?</span><span
              className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-a823"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-a823" ><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-active u-accordion-pane u-container-style u-accordion-pane-1"
                 id="accordion-ae6a" aria-labelledby="link-accordion-ae6a">
              <div className="u-container-layout u-container-layout-1">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p id="isPasted">
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>NFT stands for "non-fungible token". An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or a game token. Because of this, NFTs have collectible value.</span>
                  </p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-accordion-item">
            <a
              className="u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-2"
              id="link-accordion-8339" aria-controls="accordion-8339" aria-selected="false">
              <span className="u-accordion-link-text"> How can I buy a Gangster Gorilla&nbsp;NFT?<br />
              </span><span className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-2"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-7d7e"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-7d7e"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-pane u-container-style u-accordion-pane-2" id="accordion-8339"
                 aria-labelledby="link-accordion-8339">
              <div className="u-container-layout u-container-layout-2">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p>
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>The first step in purchasing and holding a GG NFT, is to create a VeChainThor wallet like Sync2/VeChainThor/Comet Wallet that you can connect to our Dapp.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-accordion-item">
            <a
              className="u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-3"
              id="link-accordion-1c17" aria-controls="accordion-1c17" aria-selected="false">
              <span className="u-accordion-link-text"> What blockchain will you be using?<br />
              </span><span className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-3"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-d58c"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-d58c"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-pane u-container-style u-accordion-pane-3" id="accordion-1c17"
                 aria-labelledby="link-accordion-1c17">
              <div className="u-container-layout u-container-layout-3">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p id="isPasted">
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>Gangster Gorillaz exists on the VeChain blockchain. The NFTs are minted, &nbsp;They cannot be found on any blockchain other than VeChain. Were you to discover someone posting a Gangster Gorilla on a different blockchain or marketplace as their own work, please report it to the original creators.</span>
                  </p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-accordion-item">
            <a
              className="u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-4"
              id="link-3c17" aria-controls="3c17" aria-selected="false">
              <span className="u-accordion-link-text"> What does “MINT” mean?</span><span
              className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-4"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-c4ff"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-c4ff"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-pane u-container-style u-accordion-pane-4" id="3c17"
                 aria-labelledby="link-3c17">
              <div className="u-container-layout u-container-layout-4">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p>
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>“Minting” an NFT is, in more simple terms, uniquely publishing a token on the blockchain to make it purchasable. Each NFT minted will have its own unique “Mint number” that proves it is one of the collection.<br />
                      id="isPasted">
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-accordion-item">
            <a
              className="u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-5"
              id="link-7b2a" aria-controls="7b2a" aria-selected="false">
              <span className="u-accordion-link-text"> How many NFTs will there be?</span><span
              className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-5"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-60fd"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-60fd"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-pane u-container-style u-accordion-pane-5" id="7b2a"
                 aria-labelledby="link-7b2a">
              <div className="u-container-layout u-container-layout-5">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p>
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>At the time of release, there will be 5000 unique Gangster Gorilla NFTs. This could expand or shrink in the Future....</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-accordion-item">
            <a
              className="u-accordion-link u-active-custom-color-2 u-button-style u-custom-color-1 u-custom-font u-hover-custom-color-2 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-6"
              id="link-b400" aria-controls="b400" aria-selected="false">
              <span className="u-accordion-link-text"> How much does it cost to buy a Gangster Gorilla NFT?</span><span
              className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-6"><svg
              className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
              xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-6403"></use></svg><svg
              className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-6403"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
            </a>
            <div className="u-accordion-pane u-container-style u-accordion-pane-6" id="b400"
                 aria-labelledby="link-b400">
              <div className="u-container-layout u-container-layout-6">
                <div className="fr-view u-clearfix u-rich-text u-text">
                  <p>
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanadaka'}}>When released, minting a Gangster Gorilla costs 250 VET. After all Gangster Gorillaz are minted, the purchase price will depend on the floor price in the secondary market. In other words, the price of an individual GG NFT will be determined by collectors who choose to sell their Gangster Gorilla.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
};

export default Home;
