import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home';
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import NoPage from "./pages/NoPage";
import LearnMore from "./pages/LearnMore";
import Mint from "./pages/Mint";
import Assets from "./pages/Assets";
import BananaCrack from "./pages/BananaCrack";
import EmptyPlotAssets from "./pages/EmptyPlotAssets";
import BuildingAssets from "./pages/BuildingAssets";
import MintConcreteJungle from "./pages/MintConcreteJungle";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< LearnMore />} path='/learn-more' />
                <Route element={< Mint />} path='/mint' />
                <Route element={< Assets />} path='/assets' />
                <Route element={< BananaCrack />} path='/bananacrack' />
                <Route element={< MintConcreteJungle />} path='/mint-concretejungle' />
                <Route element={< EmptyPlotAssets /> } path='/emptyplots-assets' />
                <Route element={< BuildingAssets /> } path='/building-assets' />
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
