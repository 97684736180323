const Footer = () => {
    return <footer className="u-align-center u-clearfix u-footer u-palette-1-dark-3 u-footer" id="sec-eefa">
        <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <p className="u-small-text u-text u-text-variant u-text-1">
            </p>
        </div>
    </footer>
}

export default Footer
